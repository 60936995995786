<!--
 * @Author: your name
 * @Date: 2020-06-20 17:41:59
 * @LastEditTime: 2020-09-05 11:45:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Web\JT.Web\src\views\EBCHome\back.vue
-->
<template>
    <div class="back" title="返回首页" @click="toEBCHome">
        <span class="iconfont iconhome1" :class="{'black': color===1}"></span>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        color: {
            type: Number,
        },
    },
    data() {
        return {
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 返回色块页
        toEBCHome() {
            this.$router.push({ path: '/EBCHome' });
        },
    },
    created() {
    },
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.back
    position absolute
    right .2rem
    top .1rem
    z-index 10000
    .iconfont
        font-size .26rem
        color #fff
        cursor pointer
    .black
        color #333
</style>