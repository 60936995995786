<!--
 * @Author: DY
 * @Date: 2020-08-15 14:27:02
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-23 17:29:34
 * @Describe:
-->
<template>
    <div class="threeDimensional">
        <back v-if="isShowBtn"></back>
        <div class="content">
            <iframe :src="src" frameborder="0"></iframe>
        </div>
    </div>
</template>

<script>
import back from './../back';
export default {
    components: { back },
    props: {
        tenantType: {
            type: [Array, Object, String ],
        },
    },
    data() {
        return {
            isShowBtn: true,
            type: '',
            src: '',
        };
    },
    watch: {
        type(newVal) {
            this.loadType(newVal);
        },
        tenantType: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                this.isShowBtn = false;
                this.loadType(newVal);
            },
        },
    },
    computed: {},
    created() {
        if (this.$route.query.type) {
            this.type = this.$route.query.type;
        }
    },
    mounted() {},
    methods: {
        loadType(type) {
            if (type === 'jn') {
                this.src = 'http://www.thingjs.com/s/a9475cc57cbee56b48e8dfc4?params=105b0f77fd24654d4eebc434e9';
            } else if (type === 'dy') {
                this.src = 'http://www.thingjs.com/s/033c3c198e2520641c3a792f?params=105b0f77fd24654d4eebc434e9';
            }
        },
    },
};
</script>
<style lang="stylus" scoped>
.threeDimensional
    height 100%
    overflow hidden
    // background url(./../images/threeDimensional.png) no-repeat
    background-size 100% 100%
    position relative
    .content
        width 100%
        height 100%
        iframe
            width 100%
            height 100%
</style>